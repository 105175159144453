import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const issues = [
  {
    title: 'Constitutional Rights',
    description:
      "I have vigilantly defended our God-given rights. I have stood firmly against mandates and the unprecedented intrusion in the private lives and healthcare decisions of Idahoans. I will continue to protect the Sanctity of Life of the Unborn and the Elderly, protect our 2nd Amendment Right to bear arms, defend our Religious Freedoms, and guarantee Election Integrity.",
  },
  {
    title: 'Cut Taxes',
    description:
      "This year I was a co-sponsor of the largest tax cut in the history of Idaho. But I believe we can and should do more. I believe that property taxes are one of the most un-American taxes we pay. We must reduce property taxes. As a business owner and having raised our six children in Idaho, I know how important it is to keep your hard earned money in your own pockets. I will continue to fight to lower all taxes."
  },
  {
    title: 'School Choice & Parental Rights',
    description:
      "I believe that parents are the greatest form of “local control.” In education one size does not fit all. I will continue to champion Idaho parents' voice and choice. Curriculum should always be transparent and reflect our Idaho Values. Our children and grandchildren deserve the education that best fits their unique needs. That is why I have worked to expand career technical training, apprenticeships and community college opportunities. Our investment in education should be student centered and outcomes focused.",
  },
  {
    title: 'Cut Regulation & Limit Government',
    description:
      "Small businesses are the engine of our economy.They are simply overregulated. I have carried bills to cut red tape on Veterans and small business owners. I was awarded Idaho’s 'Champion for Regulatory Innovation' by President Trump. I will continue to fight government overreach. One of the first bills I carried increased transparency and accountability in government.",
  },
  {
    title: 'Reduce Traffic & Manage Growth',
    description:
      "As our great state continues to attract new residents, we need effective growth management and increased investments in our roads. Your time is valuable and your safety is critical. That's why, as vice-chair of the House Transportation Committee, I have successfully fought for increased funding to build and improve roads like the urgent expansion of Hwy 16 to the freeway and the widening of both Hwy 44 and Chinden.",
  }
]

const IssuesPage = () => {
  return (
    <Layout>
      <Seo title="Platform" />
      <div className="bg-gray-50">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <h2 className="text-3xl font-extrabold text-blue-900">Idaho Values. Idaho Strong.</h2>
              {/* <p className="mt-4 text-lg text-gray-500">
                Gayann is running on a platform of economic development, lower taxes, limited government, and parental empowerment in education.
              </p> */}
              <StaticImage
                className="mt-6 rounded-lg shadow border"
                src="../images/GayannSign.jpg"
                alt="Gayann Demordaunt Sign"
              />
            </div>
            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="space-y-12">
                {issues.map((issue) => (
                  <div key={issue.title}>
                    <dt className="leading-6 text-2xl font-extrabold text-red-600">{issue.title}</dt>
                    <dd className="mt-2 text-base text-gray-500">{issue.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IssuesPage;